import React, { Component } from 'react';
import { graphql } from 'gatsby';

import PropTypes from 'prop-types';
import Layout from '../components/layout/layout';

import { Footer, pageWithNavigation } from '../components/common';
import Checkout from '../components/checkout/index';
import { SecuredPages } from '../components/common/page/securedPages';

const CheckoutSuccessPage = ({ data }) => {
  return (
    <Layout
      title={data.wordings.fr.home.page_title}
      description={data.wordings.fr.home.description}
    >
      <Checkout page="CHECKOUT_SUCCESS" />
      <Footer />
    </Layout>
  );
};

CheckoutSuccessPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query CheckoutSuccessQuery {
    wordings {
      fr {
        home {
          page_title
          description
        }
      }
    }
  }
`;

export default pageWithNavigation(SecuredPages(CheckoutSuccessPage));
